<template>
    <v-row no-gutters>
        <v-col cols="12">
            <AccountBar :organizationId="$route.params.organizationId" :account="account" class="mb-6"></AccountBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link>
                    <template v-if="organization">
                    &gt; <router-link :to="{ name: 'organization-dashboard', params: { realm: this.$route.params.organizationId } }">{{ organization.name }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card class="pa-0" v-if="account">
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-app-bar-title>Account</v-app-bar-title>
                            <v-spacer/>
                            <span style="font-size: 0.7em;" class="text-end">Account ID: {{ accountIdText }}</span>
                            <!-- TODO: enable invite new users only for organizations with business or enterprise add-ons; organizations on the essential plan have only one admin -->
                            <!-- <v-btn icon :to="{ name: 'organization-invite-user', params: { organizationId: this.$route.params.organizationId } }">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn> -->
                        </v-app-bar>
                        <v-card-text>
                            <p>Created on {{ formatDate(account.created_on) }}</p>
                            <p>Status: {{ accountStatusText }}</p>
                            <p v-if="account.ref">Ref: {{ account.ref }}</p>
                            <!-- <p>{{ JSON.stringify(account) }}</p> -->
                            <!-- <p>Amount {{ formatAmount(account.currency, account.amount_csu) }}
                                <span v-if="['paid', 'activated'].includes(account.status)" class="font-weight-bold">Paid</span>
                            </p> -->
                            <!-- <template v-if="account.status === 'activated'">
                                Activated
                                <v-btn text @click="reactivate" color="green darken-2">Reactivate</v-btn>
                            </template>
                            <template v-if="account.status === 'processed'">
                                Processed
                            </template> -->

                            <p class="mb-0 pb-0">
                                <NavButton :icon-before="['fas', 'inbox']" :to="{ name: 'organization-search-order', params: { organizationId: this.$route.params.organizationId }, query: { account_id: this.$route.params.accountId } }">Account orders</NavButton>
                                <!-- <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.order_count }})</span> -->
                            </p>

                            <p class="mb-0 pb-0">
                                <NavButton :icon-before="['fas', 'paper-plane']" :to="{ name: 'organization-search-invoice', params: { organizationId: this.$route.params.organizationId }, query: { account_id: this.$route.params.accountId } }">Account invoices</NavButton>
                                <!-- <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.invoice_count }})</span> -->
                            </p>

                            <p class="mb-0 pb-0">
                                <NavButton :icon-before="['fas', 'gem']" :to="{ name: 'organization-search-payment', params: { organizationId: this.$route.params.organizationId }, query: { account_id: this.$route.params.accountId } }">Account payments</NavButton>
                                <!-- <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.payment_count }})</span> -->
                            </p>

                            <p class="mb-0 pb-0">
                                <NavButton :to="{ name: 'organization-search-refund', params: { organizationId: this.$route.params.organizationId }, query: { account_id: this.$route.params.accountId } }">
                                    <template #icon-before>
                                        <!-- Reference: https://fontawesome.com/docs/web/use-with/vue/style -->
                                        <font-awesome-layers class="fa-md mr-2">
                                            <font-awesome-icon :icon="['fas', 'gem']" class="green--text text--darken-2" fixed-width></font-awesome-icon>
                                            <font-awesome-icon :icon="['fas', 'slash']" class="grey--text text--darken-3" transform="up-2" fixed-width size="sm"></font-awesome-icon>
                                        </font-awesome-layers>
                                    </template>
                                    Account refunds
                                </NavButton>
                                <!-- <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.refund_count }})</span> -->
                            </p>

                            <p class="mb-0 pb-0">
                                <NavButton :icon-before="['fas', 'file-user']" :to="{ name: 'organization-search-account-user', params: { organizationId: this.$route.params.organizationId }, query: { account_id: this.$route.params.accountId } }">Account users</NavButton>
                                <!-- <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.user_count }})</span> -->
                            </p>

                            <p class="mb-0 pb-0">
                                <NavButton :icon-before="['fas', 'concierge-bell']" :to="{ name: 'organization-search-service', params: { organizationId: this.$route.params.organizationId }, query: { account_id: this.$route.params.accountId } }">Account services</NavButton>
                                <!-- <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.service_count }})</span> -->
                            </p>

                            <v-divider class="mt-6 mb-4"></v-divider>

                            <!-- TODO: show summary numbers for recent orders, payments, refunds, invoices, statements, each in their own table, with a link to see all of them (link to search orders with this account id as a criteria, for example), and also purchased account services, resources, and assets -->
                            <!-- <v-simple-table>
                                <template #default>
                                    <thead>
                                        <tr>
                                            <th>Product ID</th>
                                            <th>Name</th>
                                            <th>Qty</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, idx) in account.content" :key="idx">
                                            <td>
                                                {{ item.product_id }}
                                            </td>
                                            <td>
                                                {{ item.name }}
                                            </td>
                                            <td>
                                                {{ item.quantity }}
                                            </td>
                                            <td>
                                                {{ formatAmount(item.currency, item.subtotal_csu) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table> -->
                        </v-card-text>
                    </v-card>

                    <!-- TODO: temporary disabled because the code for closing/deleting accounts has been updated in the customer service, so we need to update it in admin service berfore we neable thisf rom the UI, or the close/deletel won't be complete or won't check prerequisites -->
                    <!-- <v-expansion-panels class="mt-8 mb-6">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                    <font-awesome-icon :icon="['fas', 'trash']" class="red--text"></font-awesome-icon>
                                    <span class="ml-2">Close or Delete Account</span>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <template v-if="account.is_open">
                                    <p class="mt-2">Tap on the button below to close this account.</p>
                                    <p class="font-weight-bold">There will not be any confirmation prompts. This action is reversible.</p>
                                    <p class="mb-0 pb-0">
                                        <v-btn color="red" class="white--text" @click="closeAccountImmediately">Close Account</v-btn>
                                    </p>
                                </template>

                                <p class="mt-2">Tap on the button below to delete this account, including all documents, contacts, other account data.</p>
                                <p class="font-weight-bold">There will not be any confirmation prompts. This action is not reversible.</p>
                                <p class="mb-0 pb-0">
                                    <v-btn color="red" class="white--text" @click="deleteAccountImmediately">Delete Account</v-btn>
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels> -->

                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import { fromCurrencyAmountCSU } from '@libertyio/currency-util-js';
import AccountBar from '@/components/AccountBar.vue';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import NavButton from '@/components/NavButton.vue';

function formatId(input) {
    const parts = [];
    let i = 0;
    while (i < input.length) {
        const part = input.substr(i, 5);
        parts.push(part);
        i += 5;
    }
    return parts.join('-');
}

export default {
    components: {
        AccountBar,
        AccessDeniedOverlay,
        NavButton,
    },
    data: () => ({
        account: null,
        error: null,
        forbiddenError: false,
    }),
    computed: {
        ...mapState({
            organization: (state) => state.organization,
        }),
        accountIdText() {
            return formatId(this.account.id);
        },
        accountStatusText() {
            if (!this.account) {
                return 'Unknown';
            }
            return this.account.is_open ? 'Open' : 'Closed';
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.organization(this.$route.params.organizationId).account.get({ id: this.$route.params.accountId });
                if (response) {
                    this.account = response;
                } else {
                    this.account = null;
                }
            } catch (err) {
                console.error('loadAccount failed', err);
                if (err.response?.status === 403) {
                    this.forbiddenError = true;
                }
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        viewAccountLink(item) {
            return { name: 'organization-view-account', params: { organizationId: this.$route.params.organizationId, accountId: item.id } };
        },
        formatDate(timestamp) {
            return new Date(timestamp).toLocaleDateString();
        },
        formatAmount(currency, amountCSU) {
            return fromCurrencyAmountCSU(currency, amountCSU).toStringWithCurrencySymbol();
        },
        // accountStatusDisplay(status) {
        //     if (status === 'paid') {
        //         return 'Paid, waiting to be processed..';
        //     }
        //     if (status === 'activated') {
        //         return 'Processing...';
        //     }
        //     if (status === 'completed') {
        //         return 'Fulfilled';
        //     }
        //     return null;
        // },
        async closeAccountImmediately() {
            try {
                this.$store.commit('loading', { closeAccountImmediately: true });
                const response = await this.$client.organization(this.$route.params.organizationId).account.edit({ id: this.$route.params.accountId }, { is_open: false });
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Closed account' });
                    this.loadAccount();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to close account' });
                }
                this.loadAccount();
            } catch (err) {
                console.error('closeAccountImmediately failed', err);
            } finally {
                this.$store.commit('loading', { closeAccountImmediately: false });
            }
        },
        async deleteAccountImmediately() {
            try {
                this.$store.commit('loading', { deleteAccountImmediately: true });
                const response = await this.$client.organization(this.$route.params.organizationId).account.delete({ id: this.$route.params.accountId });
                if (response?.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Deleted account' });
                    this.$router.go(-1);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete account' });
                }
                this.loadAccount();
            } catch (err) {
                console.error('deleteAccountImmediately failed', err);
            } finally {
                this.$store.commit('loading', { deleteAccountImmediately: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
    },
};
</script>
