<template>
    <span :style="combinedStyle">
        <slot name="icon-before">
            <router-link :to="to" v-if="iconBefore"><font-awesome-icon :icon="iconBefore" fixed-width class="mr-2"></font-awesome-icon></router-link>
        </slot>
        <router-link :to="to"><slot></slot></router-link>
        <slot name="icon-after">
            <router-link :to="to" v-if="iconAfter"><font-awesome-icon :icon="iconAfter" fixed-width class="ml-2"></font-awesome-icon></router-link>
        </slot>
    </span>
</template>
<script>
export default {
    props: {
        color: {
            type: String,
            default: null,
        },
        underline: {
            type: Boolean,
            default: false,
        },
        iconBefore: {
            type: Array,
            default: null,
        },
        iconAfter: {
            type: Array,
            default: null,
        },
        to: {
            type: Object,
            default: null,
        },
    },
    computed: {
        colorStyle() {
            if (this.color) {
                return `color: ${this.color};`;
            }
            return '';
        },
        underlineStyle() {
            if (this.underline) {
                return 'text-decoration: underline;';
            }
            return '';
        },
        combinedStyle() {
            const defaultStyle = 'cursor: pointer;';
            return `${defaultStyle} ${this.underlineStyle} ${this.colorStyle}`;
        },
    },
};
</script>
